import React, { useState, useEffect } from 'react';
import './Leads.css';

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [form, setForm] = useState({ name: '', phone: '', email: '', work_type: '', status: '', comments: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await fetch('https://api.webartstudio.online/api/leads');
      if (response.ok) {
        const data = await response.json();
        setLeads(data);
      } else {
        console.error('Error fetching leads:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const method = isEditing ? 'PUT' : 'POST';
    const url = isEditing ? `https://api.webartstudio.online/api/leads/${currentId}` : 'https://api.webartstudio.online/api/leads';

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      if (response.ok) {
        fetchLeads();
        setForm({ name: '', phone: '', email: '', work_type: '', status: '', comments: '' });
        setIsEditing(false);
        setCurrentId(null);
        setIsFormOpen(false);
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit = (lead) => {
    setForm(lead);
    setIsEditing(true);
    setCurrentId(lead.id);
    setIsFormOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this lead?')) {
      try {
        const response = await fetch(`https://api.webartstudio.online/api/leads/${id}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          fetchLeads();
        } else {
          console.error('Error deleting lead:', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting lead:', error);
      }
    }
  };

  const handleAddLead = () => {
    setIsEditing(false);
    setForm({ name: '', phone: '', email: '', work_type: '', status: '', comments: '' });
    setIsFormOpen(true);
  };

  return (
    <div className="container">
      <h1>Leads</h1>
      <button onClick={handleAddLead} className="add-button">Add Lead</button>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Work Type</th>
            <th>Status</th>
            <th>Comments</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead.id} className={lead.status === 'converted' ? 'converted-row' : 'not-converted-row'}>
              <td>{lead.name}</td>
              <td>{lead.phone}</td>
              <td>{lead.email}</td>
              <td>{lead.work_type}</td>
              <td>{lead.status}</td>
              <td>{lead.comments}</td>
              <td>
                <button onClick={() => handleEdit(lead)} className="edit-button">Edit</button>
                <button onClick={() => handleDelete(lead.id)} className="delete-button">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isFormOpen && (
        <div className="overlay">
          <div className="form-container">
            <h2>{isEditing ? 'Edit Lead' : 'Add Lead'}</h2>
            <form onSubmit={handleSubmit}>
              <input type="text" name="name" value={form.name} onChange={handleInputChange} placeholder="Name" required />
              <input type="text" name="phone" value={form.phone} onChange={handleInputChange} placeholder="Phone" required />
              <input type="email" name="email" value={form.email} onChange={handleInputChange} placeholder="Email" required />
              <input type="text" name="work_type" value={form.work_type} onChange={handleInputChange} placeholder="Work Type" required />
              <input type="text" name="status" value={form.status} onChange={handleInputChange} placeholder="Status" required />
              <textarea name="comments" value={form.comments} onChange={handleInputChange} placeholder="Comments"></textarea>
              <div className="button-group">
                <button type="submit">{isEditing ? 'Update' : 'Add'}</button>
                <button type="button" onClick={() => setIsFormOpen(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leads;
