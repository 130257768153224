import React, { useState, useEffect } from 'react';
import './WorkStatus.css';

const WorkStatus = () => {
  const [statuses, setStatuses] = useState([]);
  const [form, setForm] = useState({ client_name: '', company_name: '', work_status: '', deadline: '', payment_status: '', amount: '', managed_by: '', comments: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    fetchWorkStatuses();
  }, []);

  const fetchWorkStatuses = async () => {
    try {
      const response = await fetch('https://api.webartstudio.online/api/work-statuses');
      if (response.ok) {
        const data = await response.json();
        setStatuses(data);
      } else {
        console.error('Error fetching work statuses:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching work statuses:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://api.webartstudio.online/api/work-statuses${isEditing ? `/${currentId}` : ''}`, {
        method: isEditing ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });
      if (response.ok) {
        fetchWorkStatuses();
        setForm({ client_name: '', company_name: '', work_status: '', deadline: '', payment_status: '', amount: '', managed_by: '', comments: '' });
        setIsEditing(false);
        setCurrentId(null);
        setIsFormOpen(false); // Close the form after submission
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit = (status) => {
    setForm(status);
    setIsEditing(true);
    setCurrentId(status.id);
    setIsFormOpen(true); // Open the form for editing
  };

  // const handleDelete = async (id) => {
  //   if (window.confirm('Are you sure you want to delete this status?')) {
  //     try {
  //       const response = await fetch(`https://api.webartstudio.online/api/work-statuses/${id}`, {
  //         method: 'DELETE',
  //       });
  //       if (response.ok) {
  //         fetchWorkStatuses();
  //       } else {
  //         console.error('Error deleting status:', response.statusText);
  //       }
  //     } catch (error) {
  //       console.error('Error deleting status:', error);
  //     }
  //   }
  // };

  const handleAddStatus = () => {
    setIsEditing(false); // Reset isEditing state to false when adding a new status
    setForm({ client_name: '', company_name: '', work_status: '', deadline: '', payment_status: '', amount: '', managed_by: '', comments: '' }); // Clear form fields
    setIsFormOpen(true); // Open the form for adding a new status
  };

  const getStatusColor = (workStatus) => {
    const percentage = parseInt(workStatus, 10);

    if (percentage >= 70) {
      return '#C8E6C9';
    } else if (percentage >= 35) {
      return '#e6e5c8';
    } else {
      return '#FFCDD2';
    }
  };

  // Sort statuses by work_status percentage in ascending order
  const sortedStatuses = statuses.sort((a, b) => {
    const percentageA = parseInt(a.work_status, 10);
    const percentageB = parseInt(b.work_status, 10);
    return percentageA - percentageB;
  });

  return (
    <div className="container">
      <h1>Work Status</h1>
      <button onClick={handleAddStatus} className="add-button">Add Status</button>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Client Name</th>
            <th>Company Name</th>
            <th>Work Status</th>
            <th>Deadline</th>
            <th>Payment Status</th>
            <th>Amount</th>
            <th>Managed By</th>
            <th>Comments</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedStatuses.map((status, index) => (
            <tr key={status.id} style={{ backgroundColor: getStatusColor(status.work_status) }}>
              <td>{index + 1}</td>
              <td>{status.client_name}</td>
              <td>{status.company_name}</td>
              <td>{status.work_status}</td>
              <td>{status.deadline}</td>
              <td>{status.payment_status}</td>
              <td>{status.amount}</td>
              <td>{status.managed_by}</td>
              <td>{status.comments}</td>
              <td>
                <button onClick={() => handleEdit(status)} className="edit-button">Edit</button>
                {/* <button onClick={() => handleDelete(status.id)} className="delete-button">Delete</button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isFormOpen && (
        <div className="overlay">
          <div className="form-container">
            <h2>{isEditing ? 'Edit Status' : 'Add Status'}</h2>
            <form onSubmit={handleSubmit}>
              <input type="text" name="client_name" value={form.client_name} onChange={handleInputChange} placeholder="Client Name" required />
              <input type="text" name="company_name" value={form.company_name} onChange={handleInputChange} placeholder="Company Name" required />
              <input type="text" name="work_status" value={form.work_status} onChange={handleInputChange} placeholder="Work Status" required />
              <input type="text" name="deadline" value={form.deadline} onChange={handleInputChange} placeholder="Deadline" required />
              <input type="text" name="payment_status" value={form.payment_status} onChange={handleInputChange} placeholder="Payment Status" required />
              <input type="text" name="amount" value={form.amount} onChange={handleInputChange} placeholder="Amount" required />
              <input type="text" name="managed_by" value={form.managed_by} onChange={handleInputChange} placeholder="Managed By" required />
              <textarea name="comments" value={form.comments} onChange={handleInputChange} placeholder="Comments"></textarea>
              <div className="button-group">
                <button type="submit">{isEditing ? 'Update' : 'Add'}</button>
                <button type="button" onClick={() => setIsFormOpen(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkStatus;
