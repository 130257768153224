// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/navbar/Navbar';
import Leads from './pages/leads/Leads';
import WorkStatus from './pages/workstatus/WorkStatus';


const App = () => {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/leads" element={<Leads/>} />
        <Route path="/workstatus" element={<WorkStatus/>} />
      </Routes>
    </Router>
    
  );
};

export default App;